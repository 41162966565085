define("discourse/plugins/discourse-asana-plugin/admin/templates/connectors/admin-dashboard-tabs-after/additional_tabs", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <li class="navigation-item international">
    <LinkTo @route="admin-dashboard-international" class="navigation-link">
      International
    </LinkTo>
  </li>
  <li class="navigation-item ambassadors">
    <LinkTo @route="admin-dashboard-ambassadors" class="navigation-link">
      Ambassadors
    </LinkTo>
  </li>
  <li class="navigation-item leaders">
    <LinkTo @route="admin-dashboard-leaders" class="navigation-link">
      Forum Leaders
    </LinkTo>
  </li>
  */
  {
    "id": "iB/KPwHd",
    "block": "[[[10,\"li\"],[14,0,\"navigation-item international\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"navigation-link\"]],[[\"@route\"],[\"admin-dashboard-international\"]],[[\"default\"],[[[[1,\"\\n    International\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"li\"],[14,0,\"navigation-item ambassadors\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"navigation-link\"]],[[\"@route\"],[\"admin-dashboard-ambassadors\"]],[[\"default\"],[[[[1,\"\\n    Ambassadors\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"li\"],[14,0,\"navigation-item leaders\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"navigation-link\"]],[[\"@route\"],[\"admin-dashboard-leaders\"]],[[\"default\"],[[[[1,\"\\n    Forum Leaders\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"li\",\"link-to\"]]",
    "moduleName": "discourse/plugins/discourse-asana-plugin/admin/templates/connectors/admin-dashboard-tabs-after/additional_tabs.hbs",
    "isStrictMode": false
  });
});