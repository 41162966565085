define("discourse/plugins/discourse-asana-plugin/admin/templates/connectors/admin-dashboard-general-bottom/additional_reports", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AdminReport
    @dataSourceName="response_rate_for_categories"
    @showTrend={{true}}
    @forcedModes="chart"
    @filters={{this.filters}}
  />
  
  <AdminReport
    @dataSourceName="resolution_rate_for_categories"
    @showTrend={{true}}
    @forcedModes="chart"
    @filters={{this.filters}}
  />
  
  <AdminReport
    @dataSourceName="response_time_for_categories"
    @showTrend={{true}}
    @forcedModes="chart"
    @filters={{this.filters}}
  />
  */
  {
    "id": "/KmkaL/C",
    "block": "[[[8,[39,0],null,[[\"@dataSourceName\",\"@showTrend\",\"@forcedModes\",\"@filters\"],[\"response_rate_for_categories\",true,\"chart\",[30,0,[\"filters\"]]]],null],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@dataSourceName\",\"@showTrend\",\"@forcedModes\",\"@filters\"],[\"resolution_rate_for_categories\",true,\"chart\",[30,0,[\"filters\"]]]],null],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@dataSourceName\",\"@showTrend\",\"@forcedModes\",\"@filters\"],[\"response_time_for_categories\",true,\"chart\",[30,0,[\"filters\"]]]],null]],[],false,[\"admin-report\"]]",
    "moduleName": "discourse/plugins/discourse-asana-plugin/admin/templates/connectors/admin-dashboard-general-bottom/additional_reports.hbs",
    "isStrictMode": false
  });
});