define("discourse/plugins/discourse-asana-plugin/admin/templates/dashboard-ambassadors", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="period-section section ambassadors">
    <div class="section-title">
      <h2>
        Ambassadors
      </h2>
  
      <DashboardPeriodSelector
        @period={{this.period}}
        @setPeriod={{this.setPeriod}}
        @startDate={{this.startDate}}
        @endDate={{this.endDate}}
        @setCustomDateRange={{this.setCustomDateRange}}
      />
    </div>
  
    <div class="section-body">
      <div class="charts">
        <AdminReport
          @dataSourceName="topics_by_group"
          @forcedModes="chart"
          @filters={{this.filters}}
        />
  
        <AdminReport
          @dataSourceName="posts_by_group"
          @forcedModes="chart"
          @filters={{this.filters}}
        />
  
        <AdminReport
          @dataSourceName="accepted_solutions_by_group"
          @forcedModes="chart"
          @filters={{this.filters}}
        />
  
        <AdminReport
          @dataSourceName="likes_by_group"
          @forcedModes="chart"
          @filters={{this.filters}}
        />
      </div>
    </div>
  </div>
  */
  {
    "id": "SYGnB7PM",
    "block": "[[[10,0],[14,0,\"period-section section ambassadors\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"section-title\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"\\n      Ambassadors\\n    \"],[13],[1,\"\\n\\n    \"],[8,[39,2],null,[[\"@period\",\"@setPeriod\",\"@startDate\",\"@endDate\",\"@setCustomDateRange\"],[[30,0,[\"period\"]],[30,0,[\"setPeriod\"]],[30,0,[\"startDate\"]],[30,0,[\"endDate\"]],[30,0,[\"setCustomDateRange\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"section-body\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"charts\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@dataSourceName\",\"@forcedModes\",\"@filters\"],[\"topics_by_group\",\"chart\",[30,0,[\"filters\"]]]],null],[1,\"\\n\\n      \"],[8,[39,3],null,[[\"@dataSourceName\",\"@forcedModes\",\"@filters\"],[\"posts_by_group\",\"chart\",[30,0,[\"filters\"]]]],null],[1,\"\\n\\n      \"],[8,[39,3],null,[[\"@dataSourceName\",\"@forcedModes\",\"@filters\"],[\"accepted_solutions_by_group\",\"chart\",[30,0,[\"filters\"]]]],null],[1,\"\\n\\n      \"],[8,[39,3],null,[[\"@dataSourceName\",\"@forcedModes\",\"@filters\"],[\"likes_by_group\",\"chart\",[30,0,[\"filters\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"h2\",\"dashboard-period-selector\",\"admin-report\"]]",
    "moduleName": "discourse/plugins/discourse-asana-plugin/admin/templates/dashboard-ambassadors.hbs",
    "isStrictMode": false
  });
});